import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
// materialUI imports
import { Box } from "@mui/material";
// project imports
import { useAuthHeaders } from "../../auth/AuthHeaders";
import ConfigContext from "../../store/context/ConfigContext";
import CreditResponse from "../response/CreditResponse";
import CreditForm, { countryPurposeCodePatterns } from "./CreditForm";
import useEntityDetails from "../../store/hooks/useEntityDetails";
import useSubmitCredit from "../../store/hooks/useSubmitCredit";
// 3rd Party imports
import * as Yup from "yup";
import { Formik } from "formik";

// ============================|| Create - Credit ||============================ //
const CreateUnknownCurrencyCredit = ({
  EntityId,
  fxDealDetails,
  entityName,
  whichInputTouched,
}) => {
  const responseRef = React.useRef(null);
  const [paymentType] = useState(
    fxDealDetails.response.id ? "Foreign" : "Domestic",
  );
  const config = useContext(ConfigContext);
  const authHeaders = useAuthHeaders();
  const [responseModalOpen, setResponseModalOpen] = useState(false);
  const [confirmationData, setConfirmationData] = useState("");
  const { entityDetails } = useEntityDetails(EntityId);

  const { handleSubmit: handleCreditSubmit, response: creditResponse } =
    useSubmitCredit(authHeaders, config, paymentType);

  const handleConfirmationDataChange = (data) => {
    setConfirmationData(data);
  };

  useEffect(() => {
    if (creditResponse) {
      setResponseModalOpen(true);
    }
  }, [creditResponse]);

  const clearCreditResponse = () => {
    setResponseModalOpen(false);
    setConfirmationData(null);
  };

  const getCurrentFormattedDate = () => {
    const today = new Date();
    return `${String(today.getMonth() + 1).padStart(2, "0")}-${String(today.getDate()).padStart(2, "0")}-${today.getFullYear()} -${String(today.getHours()).padStart(2, "0")}:${String(today.getMinutes()).padStart(2, "0")}`;
  };

  const country = entityDetails?.details?.visab2b?.address?.country;

  const validationSchema = Yup.object().shape({
    reference: Yup.string().required("Internal Tracking Reference is required"),
    account: Yup.string(),
    amount: Yup.number().required("Amount is required"),
    currency: Yup.string().required("Currency is required"),
    entityId: Yup.string().required("Entity Reference is required"),
    when: Yup.string().required("When is required"),
    description: Yup.string()
      .required("Purpose is required")
      .max(35, "Purpose can't be longer than 35 characters"),
    descriptionOther: Yup.string().when("description", {
      is: (description) => description === "Other",
      then: (schema) =>
        schema
          .required("Please specify the purpose")
          .max(35, "Purpose can't be longer than 35 characters"),
      otherwise: (schema) => schema.notRequired(),
    }),
    transactionNote: Yup.string().max(
      1024,
      "Transaction note must be a maximum of 1024 characters",
    ),
    purposeCode: Yup.string().when([], {
      is: () => !!countryPurposeCodePatterns[country],
      then: (schema) =>
        schema
          .matches(countryPurposeCodePatterns[country], "Invalid purpose code")
          .required("Purpose Code is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
  });

  return (
    <Box mt={2}>
      <Formik
        initialValues={{
          reference: `${entityName} - ${getCurrentFormattedDate()}`,
          account: undefined,
          amount:
            whichInputTouched === "buyAmount"
              ? fxDealDetails.response.buyAmount
              : fxDealDetails.response.sellAmount,
          currency:
            whichInputTouched === "buyAmount"
              ? fxDealDetails.request.currency
              : fxDealDetails.sendCurrency,
          entityId: EntityId,
          when: "standard",
          description: "",
          fxQuoteId: fxDealDetails.response.id
            ? fxDealDetails.response.id
            : null,
          ledgerId: fxDealDetails.request.ledgerId,
          transactionNote: undefined,
          purposeCode: undefined,
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          const formData = {
            ...values,
            totp: values.totpValue,
          };
          await handleCreditSubmit(formData, { setSubmitting });
          setSubmitting(false);
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          touched,
          errors,
          setSubmitting,
          validateForm,
          setTouched,
        }) => (
          <CreditForm
            values={values}
            handleChange={handleChange}
            handleBlur={handleBlur}
            touched={touched}
            errors={errors}
            validateForm={validateForm}
            setTouched={setTouched}
            requestData={fxDealDetails}
            onConfirmationDataChange={handleConfirmationDataChange}
            handleSubmit={handleSubmit}
            setSubmitting={setSubmitting}
            entityDetails={entityDetails}
            entityName={entityName}
            fxDealDetails={fxDealDetails}
            whichInputTouched={whichInputTouched}
            country={country}
          />
        )}
      </Formik>

      {creditResponse && (
        <Box mt={2} ref={responseRef}>
          <CreditResponse
            response={creditResponse}
            open={responseModalOpen}
            clearResponse={clearCreditResponse}
          />
        </Box>
      )}
    </Box>
  );
};

CreateUnknownCurrencyCredit.propTypes = {
  EntityId: PropTypes.string.isRequired,
  fxDealDetails: PropTypes.object.isRequired,
  entityName: PropTypes.string.isRequired,
  entityCountry: PropTypes.string,
  whichInputTouched: PropTypes.string.isRequired,
};

export default CreateUnknownCurrencyCredit;
