//3rd party imports
import { useSelector } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";

// defaultTheme
import themes from "themes";

// project imports
import NavigationScroll from "layout/NavigationScroll";
import MainLayout from "./layout";
import { AuthContext } from "./auth/AuthContext";
import Welcome from "./views/Welcome";
import Quote from "./views/Quote";
import Home from "./views/Home";
import { useContext } from "react";
import LoginScreen from "./views/LoginScreen";
import EnrollView from "./views/Enroll";
import LoginScreenWithMessage from "./views/LoginScreenWithMessage";
import CreateBeneficiary from "./views/CreateBeneficiary";
import ViewBeneficiaries from "./views/ViewBeneficiaries";
import ConfigContext from "./store/context/ConfigContext";
import RolesList from "./views/RolesList";
import Invite from "./views/Invite";
import MakePayment from "./requests/MakePayment";
import Approvals from "./views/Approvals";
import ViewTransactions from "./views/ViewTransactions";
import Ledgers from "./views/Ledgers";
import Transfer from "./views/Transfer";
import BankTransactionView from "./views/BankTransaction";
import BankTransactionHistory from "./requests/BankTransactionHistory";
import { AccountSettings } from "./views/AccountSettings";
import Tos from "./views/Tos";
import BankApprovals from "./views/BankApprovals";

// ==============================|| APP - Content ||============================== //

const AppContent = () => {
  const { isAuthenticated } = useContext(AuthContext);
  if (isAuthenticated) {
    return (
      <MainLayout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Tos" element={<Tos />} />
          <Route path="/quote" element={<Quote />} />
          <Route path="/create-beneficiary" element={<CreateBeneficiary />} />
          <Route path="/payment" element={<MakePayment />} />
          <Route path="/invite-user" element={<Invite />} />
          <Route path="/users" element={<RolesList />} />
          <Route path="/transfer" element={<Transfer />} />
          <Route path="/ledgers" element={<Ledgers />} />
          <Route path="/bank-transaction" element={<BankTransactionView />} />
          <Route
            path="/bank-transaction-history"
            element={<BankTransactionHistory />}
          />
          <Route path="/approvals/:uuid?" element={<Approvals />} />
          <Route path="/bank-approvals" element={<BankApprovals />} />
          <Route
            path="/token-expiry"
            element={<LoginScreenWithMessage snackbarType="token-expiry" />}
          />
          <Route path="/transaction-history" element={<ViewTransactions />} />
          <Route path="/view-beneficiary" element={<ViewBeneficiaries />} />
          <Route path="/account-settings" element={<AccountSettings />} />
          <Route path="*" element={<Welcome />} />
        </Routes>
      </MainLayout>
    );
  } else {
    return (
      <Routes>
        <Route
          path="/success-now-login"
          element={<LoginScreenWithMessage snackbarType="success" />}
        />
        <Route path="/enroll/:uuid" element={<EnrollView />} />
        <Route path="/login" element={<LoginScreen />} />
        <Route
          path="/logout"
          element={<LoginScreenWithMessage snackbarType="logout" />}
        />
        <Route
          path="/token-expiry"
          element={<LoginScreenWithMessage snackbarType="token-expiry" />}
        />
        <Route
          path="/roles-updated"
          element={<LoginScreenWithMessage snackbarType="rolesChanged" />}
        />
        <Route
          path="/permission-denied"
          element={<LoginScreenWithMessage snackbarType="permissionDenied" />}
        />
        <Route path="*" element={<LoginScreen />} />
      </Routes>
    );
  }
};

// ==============================|| APP ||============================== //

const App = () => {
  const customization = useSelector((state) => state.customization);
  const webConfig = useContext(ConfigContext);
  const mergedThemeConfig = {
    ...customization,
    colors: webConfig?.colors || {},
  };

  return (
    <StyledEngineProvider injectFirst>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ThemeProvider theme={themes(mergedThemeConfig)}>
          <CssBaseline />
          <NavigationScroll>
            <AppContent />
          </NavigationScroll>
        </ThemeProvider>
      </LocalizationProvider>
    </StyledEngineProvider>
  );
};

export default App;
