import React, { useEffect, useState } from "react";

// materialUI imports
import {
  Button,
  Divider,
  Grid,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";

// project imports
import { InfoText } from "../../ui-component/extended/InfoText";
import CustomFormField from "../../ui-component/form-fields/CustomFormField";
import CustomCurrencyValueInput from "../../ui-component/form-fields/CustomCurrencyValueInput";
import CustomDropdown from "../../ui-component/form-fields/CustomDropdown";
import ConfirmationModal from "../../ui-component/extended/modal/ConfirmationModal";
import { getCurrencyFlag } from "../../common/currencyFlagHelper";
import { getCurrencySymbol } from "../../store/getFormattedCurrencyAmount";

// 3rd Party imports
import { Form, useFormikContext } from "formik";
import * as PropTypes from "prop-types";
import { IconReplace } from "@tabler/icons-react";
import currency from "currency.js";
import OtpInput from "react18-input-otp";
import AddIcon from "@mui/icons-material/Add";

//Todo: get rid of this and replace with the locale call.... but for now this defines regex patterns for countries
export const countryPurposeCodePatterns = {
  BH: /^[A-Z]{3}$/,
  JO: /^[0-9]{4}$/,
  MN: /^VO[0-9]{5}$/,
  UY: /^VO[0-9]{5}$/,
  AE: /^[A-Z]{3}$/,
};
// ============================|| CREDIT FORM ||============================ //
const CreditForm = ({
  values,
  handleChange,
  handleBlur,
  touched,
  errors,
  validateForm,
  setTouched,
  requestData,
  fxCredit,
  fxDealDetails,
  onConfirmationDataChange,
  handleSubmit,
  entityDetails,
  entityName,
  whichInputTouched,
  isDomestic,
  country,
}) => {
  const { setFieldValue } = useFormikContext();
  const [showInfo, setShowInfo] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [confirmationData, setConfirmationData] = useState("");
  const [displayedCurrency, setDisplayedCurrency] = useState(
    whichInputTouched === "buyAmount"
      ? requestData.request.currency
      : requestData.sendCurrency,
  );
  const [totpValue, setTotpValue] = useState();
  const [deliveryOption, setDeliveryOption] = useState("standard");
  const [paymentDescriptions, setPaymentDescriptions] = useState(null);
  const [showFreeText, setShowFreeText] = useState(false);
  const [showAddenda, setShowAddenda] = useState(false);

  // Fetch payment descriptions from sessionStorage on component mount
  useEffect(() => {
    try {
      const storedDescriptions = sessionStorage.getItem("paymentDescriptions");
      if (storedDescriptions) {
        const descriptions = storedDescriptions.split(",");
        if (Array.isArray(descriptions)) {
          setPaymentDescriptions([...descriptions, "Other"]);
        }
      }
    } catch (error) {
      console.error(
        "Error parsing paymentDescriptions from sessionStorage:",
        error,
      );
    }
  }, []);

  const handleDeliveryChange = (event, newDeliveryOption) => {
    if (newDeliveryOption !== null) {
      setDeliveryOption(newDeliveryOption);
    }
  };

  const handleTOTPChange = (totp) => {
    setTotpValue(totp);
  };

  const handleConfirmationOpen = (values) => {
    setConfirmationData(values);
    setConfirmationModalOpen(true);
    onConfirmationDataChange(values);
  };

  const handleConfirmationClose = () => {
    setConfirmationModalOpen(false);
  };

  const handleDescriptionChange = (e) => {
    const selectedValue = e.target.value;
    if (selectedValue === "Other") {
      setShowFreeText(true);
      setFieldValue("descriptionOther", ""); // Use a different field for free text input
      setFieldValue("description", selectedValue); // Keep the value of the dropdown
    } else {
      setShowFreeText(false);
      setFieldValue("description", selectedValue);
      setFieldValue("descriptionOther", "");
    }
  };

  const handleAddendaToggle = () => {
    setShowAddenda((prevShowAddenda) => !prevShowAddenda);
  };

  return (
    <Form style={{ width: "100%", maxWidth: "800px" }}>
      <div
        onClick={() => setShowInfo((prev) => !prev)}
        style={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          padding: "5px",
        }}
      >
        <Typography
          variant="h6"
          component="h2"
          gutterBottom
          style={{ marginRight: "8px" }}
        >
          Need Help?
        </Typography>
      </div>
      <Grid container spacing={1}>
        <Grid item xs={8}>
          <InfoText
            text={
              "A unique reference for this request. This is generated and unable to be changed "
            }
            showInfo={showInfo}
          />
          <CustomFormField
            name="reference"
            label="Internal Tracking Reference"
            value={values.reference}
            handleChange={handleChange}
            handleBlur={handleBlur}
            touched={touched}
            errors={errors}
            validateChars={true}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <InfoText
            text={
              "The beneficiary name also known as the payee. Funds will be transferred from the\n" +
              "payer (the client) to the payee."
            }
            showInfo={showInfo}
          />
          {/* View-only value for customer */}
          <CustomFormField
            name="entityName"
            label="Beneficiary Name"
            value={entityName}
            handleChange={handleChange}
            handleBlur={handleBlur}
            touched={touched}
            errors={errors}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <CustomCurrencyValueInput
            name="amount"
            label="Amount"
            value={values.amount}
            handleChange={handleChange}
            handleBlur={handleBlur}
            touched={touched}
            errors={errors}
            disabled={fxDealDetails}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <CustomDropdown
            name="currency"
            label="Currency"
            value={displayedCurrency}
            handleChange={(e) => {
              setDisplayedCurrency(e.target.value);
              handleChange(e);
            }}
            handleBlur={handleBlur}
            touched={touched}
            errors={errors}
            disabled={fxDealDetails}
            options={[
              ...(displayedCurrency
                ? [
                    {
                      value: displayedCurrency,
                      label: displayedCurrency,
                      icon: getCurrencyFlag(displayedCurrency),
                    },
                  ]
                : []),
              ...(displayedCurrency !== "USD"
                ? [
                    {
                      value: "USD",
                      label: "USD",
                      icon: getCurrencyFlag("USD"),
                    },
                  ]
                : []),
              ...(requestData?.currency &&
              requestData.currency !== "USD" &&
              requestData.currency !== displayedCurrency
                ? [
                    {
                      value: requestData.currency,
                      label: requestData.currency,
                      icon: getCurrencyFlag(requestData.currency),
                    },
                  ]
                : []),
              ...(fxDealDetails?.currency &&
              fxDealDetails.currency !== "USD" &&
              fxDealDetails.currency !== requestData?.currency &&
              fxDealDetails.currency !== displayedCurrency
                ? [
                    {
                      value: fxDealDetails.currency,
                      label: fxDealDetails.currency,
                      icon: getCurrencyFlag(fxDealDetails.currency),
                    },
                  ]
                : []),
            ]}
          />
        </Grid>
        {isDomestic && (
          <Grid item xs={12} my={2}>
            <Typography variant="subtitle1" sx={{ mb: 2 }}>
              Select Delivery Option:
            </Typography>
            <ToggleButtonGroup
              value={deliveryOption}
              exclusive
              onChange={handleDeliveryChange}
              aria-label="Delivery option"
              color="primary"
            >
              <ToggleButton value="standard" aria-label="Standard">
                Standard
              </ToggleButton>
              <ToggleButton value="sameDay" aria-label="Same Day">
                Same Day
              </ToggleButton>
              <ToggleButton value="instant" aria-label="Instant">
                Instant
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        )}
        {fxCredit && values.amount > 0.01 && (
          <Grid item xs={10}>
            <Typography
              variant="body1"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "8px",
                backgroundColor: "#f4f4f4",
                borderRadius: "8px",
                padding: "8px",
                color: "#012169",
                fontWeight: "bold",
                fontSize: "1rem",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              }}
            >
              <div
                style={{ display: "flex", alignItems: "center", gap: "8px" }}
              >
                <span>Total in</span>
                <span
                  style={{
                    color: "#1E88E5",
                    textTransform: "uppercase",
                    fontWeight: "bold",
                  }}
                >
                  {requestData.currency !== displayedCurrency
                    ? requestData.currency
                    : "USD"}
                </span>
                <span>:</span>
                <span style={{ fontWeight: "bold", color: "#1E88E5" }}>
                  {displayedCurrency === requestData.currency
                    ? currency(values.amount, {
                        symbol: getCurrencySymbol("USD"),
                      })
                        .multiply(requestData.inverse)
                        .format()
                    : currency(values.amount, {
                        symbol: getCurrencySymbol(requestData.currency),
                      })
                        .multiply(requestData.rate)
                        .format()}
                </span>
              </div>
              <IconButton
                onClick={() =>
                  setDisplayedCurrency(
                    displayedCurrency === "USD" ? requestData.currency : "USD",
                  )
                }
                color="primary"
                size="small"
                sx={{
                  backgroundColor: "#00B5E2",
                  color: "#FFF",
                  "&:hover": { backgroundColor: "#00A1D6" },
                }}
              >
                <IconReplace sx={{ fontSize: "1rem" }} />
              </IconButton>
            </Typography>
          </Grid>
        )}
        <Grid item xs={10} container alignItems="center" spacing={1}>
          {paymentDescriptions && !isDomestic ? (
            <>
              <Grid item xs={11}>
                <InfoText
                  text={"A description of the transaction purpose"}
                  showInfo={showInfo}
                />
                <CustomDropdown
                  name="description"
                  label="Purpose"
                  value={values.description}
                  handleChange={handleDescriptionChange}
                  handleBlur={handleBlur}
                  touched={touched}
                  errors={errors}
                  options={paymentDescriptions.map((desc) => ({
                    label: desc,
                    value: desc,
                  }))}
                />
              </Grid>
              <Grid item xs={1}>
                <Tooltip title="Add optional Addenda">
                  <IconButton
                    onClick={handleAddendaToggle}
                    style={{ cursor: "pointer" }}
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
              {showFreeText && (
                <Grid item xs={12}>
                  <CustomFormField
                    name="descriptionOther"
                    label="Please specify"
                    value={values.descriptionOther}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched}
                    errors={errors}
                    validateChars={true}
                  />
                </Grid>
              )}
            </>
          ) : (
            <>
              <Grid item xs={11}>
                <CustomFormField
                  name="description"
                  label="Purpose"
                  value={values.description}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  touched={touched}
                  errors={errors}
                  maxLength={isDomestic ? 10 : 35}
                  validateChars={true}
                />
              </Grid>
              <Grid item xs={1}>
                <Tooltip title="Add optional Addenda">
                  <IconButton
                    onClick={handleAddendaToggle}
                    style={{ cursor: "pointer" }}
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </>
          )}
        </Grid>
        {showAddenda && (
          <Grid item xs={8}>
            <CustomFormField
              name="addenda"
              label="Addenda"
              value={values.addenda}
              handleChange={handleChange}
              handleBlur={handleBlur}
              touched={touched}
              errors={errors}
              maxLength={1024}
              validateChars={true}
            />
          </Grid>
        )}
        <Grid item xs={8}>
          <CustomFormField
            name="transactionNote"
            label="Note"
            value={values.transactionNote}
            handleChange={handleChange}
            handleBlur={handleBlur}
            touched={touched}
            errors={errors}
            maxLength={1024}
            validateChars={true}
          />
        </Grid>
        {countryPurposeCodePatterns[country] && (
          <Grid item xs={12} sm={8}>
            <CustomFormField
              name="purposeCode"
              label="Purpose Code"
              value={values.purposeCode}
              handleChange={handleChange}
              handleBlur={handleBlur}
              touched={touched}
              errors={errors}
            />
          </Grid>
        )}
        {isDomestic && (
          <Grid item xs={12} my={2}>
            <Divider />
            <Typography sx={{ mt: 2, ml: 2 }}>
              Enter 6-digit authentication code:
            </Typography>
            <OtpInput
              name="totp"
              value={values.totp}
              onChange={(value) => setFieldValue("totp", value)}
              numInputs={6}
              containerStyle={{ justifyContent: "space-between" }}
              inputStyle={{
                width: "100%",
                margin: "8px",
                padding: "10px",
                border: `1px solid `,
                borderRadius: 4,
                ":hover": {
                  borderColor: "#000",
                },
              }}
              focusStyle={{
                outline: "none",
                border: `2px solid #000`,
              }}
            />
            <Divider sx={{ my: 2 }} />
          </Grid>
        )}
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="secondary"
            onClick={async () => {
              const errors = await validateForm();
              if (Object.keys(errors).length === 0) {
                if (isDomestic) {
                  const updatedValues = {
                    ...values,
                    totp: totpValue,
                  };

                  await handleSubmit(updatedValues);
                } else {
                  handleConfirmationOpen({ ...values, totpValue });
                }
              } else {
                setTouched({
                  reference: true,
                  amount: true,
                  description: true,
                });
              }
            }}
          >
            Confirm Transaction
          </Button>
        </Grid>
        {confirmationData && (
          <ConfirmationModal
            open={confirmationModalOpen}
            onCancel={handleConfirmationClose}
            onConfirm={async () => {
              values.totpValue = totpValue;
              await handleSubmit(values);
              handleConfirmationClose();
            }}
            requestData={requestData}
            displayedCurrency={displayedCurrency}
            entityDetails={entityDetails}
            onTOTPChange={handleTOTPChange}
          />
        )}
      </Grid>
    </Form>
  );
};

CreditForm.propTypes = {
  values: PropTypes.object,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  touched: PropTypes.object,
  errors: PropTypes.object,
  validateForm: PropTypes.func,
  setTouched: PropTypes.func,
  requestData: PropTypes.any,
  fxCredit: PropTypes.bool,
  fxDealDetails: PropTypes.object,
  onConfirmationDataChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func,
  entityDetails: PropTypes.object,
  entityName: PropTypes.string,
  whichInputTouched: PropTypes.string,
  isDomestic: PropTypes.bool,
  country: PropTypes.string,
};

export default CreditForm;
